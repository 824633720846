import React from "react";
import Box from "@mui/material/Box";
import "../style/font-style.css";

const WrapBoxTypo = (props) => {
  return (
    <Box
      {...props}
      lineHeight={props.lh ? props.lh : "200%"}
      fontSize={props.fs ? props.fs : "16px"}
      letterSpacing={props.ls ? props.ls : "2px"}
      fontWeight={props.fw ? props.fw : 400}
      color={props.color ? props.color : "#515D59"}
    >
      <p
        style={{
          margin: "0",
          fontFamily: props.isCooperItalic
            ? "CooperMdItalic,yumincho,serif"
            : props.Notosans
            ? "Noto Sans JP, sans-serif"
            : props.Yumincho
            ? "yumincho,serif"
            : props.Tsukubrdgothic
            ? "fot-tsukubrdgothic-std"
            : props.Luam
            ? "Luam-Bold,Noto Sans JP, sans-serif"
            : props.Marcellus
            ? "Marcellus"
            : props.MetropolisMedium
            ? "Metropolis-Medium"
            : "Noto Sans JP, sans-serif",
        }}
      >
        {props.children}
      </p>
    </Box>
  );
};

export const H2 = (props) => {
  return (
    <WrapBoxTypo
      {...props}
      m={props.m ? props.m : "56px auto"}
      fs={props.fs ? props.fs : ["24px", "26px"]}
      ls={props.ls ? props.ls : "2px"}
      lh={["200%"]}
      fw={props.fw ? props.fw : "#500"}
      color={props.color ? props.color : "#515D59"}
      className="tac"
    >
      {props.children}
    </WrapBoxTypo>
  );
};
export const H3 = (props) => {
  return (
    <WrapBoxTypo
      {...props}
      m={props.m ? props.m : "48px auto 0 auto"}
      fs={["18px", "20px"]}
      ls={["1px"]}
      lh={props.lh ? props.lh : "200%"}
      fw="500"
      color={props.color ? props.color : "#515D59"}
    >
      {props.children}
    </WrapBoxTypo>
  );
};
export const P = (props) => {
  return (
    <WrapBoxTypo
      {...props}
      m={props.m ? props.m : "0"}
      fs={props.fs ? props.fs : ["14px", "16px"]}
      ls={props.ls ? props.ls : "2px"}
      lh={props.lh ? props.lh : "200%"}
      fw={props.fw ? props.fw : "400"}
      color={props.color ? props.color : "#515D59"}
    >
      {props.children}
    </WrapBoxTypo>
  );
};
export default WrapBoxTypo;
